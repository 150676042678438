/**
 * @license
 * MyFonts Webfont Build ID 3227963, 2016-06-03T13:47:09-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FuturaBT-Book by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/book/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * Licensed pageviews: 10,000
 *
 * Webfont: ClassicalGaramondBT-Italic by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/classical-garamond/italic/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * Licensed pageviews: 10,000
 *
 * Webfont: NeueHaasUnicaPro-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-haas-unica/bold/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 * Licensed pageviews: 250,000
 *
 * Webfont: NeueHaasUnicaPro-Regular by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-haas-unica/regular/
 * Copyright: Copyright &#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3227963
 *
 * © 2016 MyFonts Inc
*/


@font-face {
    font-family: "FuturaBT-Book";
    src: url("../fonts/323FB9_0_0.eot");
    src: url("../fonts/323FB9_0_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/323FB9_0_0.woff2") format("woff2"),
    url("../fonts/323FB9_0_0.woff") format("woff"),
    url("../fonts/323FB9_0_0.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "ClassicalGaramondBT-Italic";
    src: url("../fonts/31413B_1_0.eot");
    src: url("../fonts/31413B_1_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/31413B_1_0.woff2") format("woff2"),
    url("../fonts/31413B_1_0.woff") format("woff"),
    url("../fonts/31413B_1_0.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NeueHaasUnicaPro-Bold";
    src: url("../fonts/319490_0_0.eot");
    src: url("../fonts/319490_0_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/319490_0_0.woff2") format("woff2"),
    url("../fonts/319490_0_0.woff") format("woff"),
    url("../fonts/319490_0_0.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "NeueHaasUnicaPro-Medium";
    src: url("../fonts/3133110011.otf");
    font-display: swap;
}

@font-face {
    font-family: "NeueHaasUnicaPro-Regular";
    src: url("../fonts/31413B_3_0.eot");
    src: url("../fonts/31413B_3_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/31413B_3_0.woff2") format("woff2"),
    url("../fonts/31413B_3_0.woff") format("woff"),
    url("../fonts/31413B_3_0.ttf") format("truetype");
    font-display: swap;
}



@font-face {
    font-family: "NeueHaasUnicaPro-Medium";
    src: url("../fonts/Neue-Haas-Unica-Pro-Medium.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?uxj1ri");
    src: url("../fonts/icomoon.eot?uxj1ri#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?uxj1ri") format("truetype"),
    url("../fonts/icomoon.woff?uxj1ri") format("woff"),
    url("../fonts/icomoon.svg?uxj1ri#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_55_Roman.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_55_Roman.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_55_Oblique.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_55_Oblique.woff") format("woff");
    font-weight: normal;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_65_Medium.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_65_Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_65_Medium_Obliqu.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_65_Medium_Obliqu.woff") format("woff");
    font-weight: 500;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_35_Light.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_35_Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_35_Light_Oblique.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_35_Light_Oblique.woff") format("woff");
    font-weight: 300;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_45_Book.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_45_Book.woff") format("woff");
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_45_Book_Oblique.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_45_Book_Oblique.woff") format("woff");
    font-weight: 350;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_85_Heavy.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_85_Heavy.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir_LT_W05_85_Heavy_Oblique.woff2") format("woff2"),
    url("../fonts/Avenir_LT_W05_85_Heavy_Oblique.woff") format("woff");
    font-weight: 700;
    font-style: oblique;
    font-display: swap;
}
